<template>
    <div class="prc2025" id="platinumContest2025">
        <AppNavBar />
        <div class="container">
            <div class="row">
                <div class="col s12 m8 offset-m2 animated bounceInUp">
                <div class="card">
                    <div class="card-image">
                        <router-link to="PRC2025" class="">
                            <img :src="require('@/assets/prc2025_1.jpg')" />
                        </router-link>
                    </div>
                    <div class="card-content black-text center-align">
                        <span class="btn-small grey lighten-3 black-text">
                            Platinum Reasoning Contest 2025
                        </span>
                        <br/><br/>
                        <p class="bold">
                            All schools are expected to carefully review the instructions below before proceeding with student(s) registration.
                        </p>
                    </div>
                </div>
                </div>
            </div>
            <div class="section">
                <h4 class="center-align blue-text text-darken-3 animated pulse infinite waves-ripple">🌟 PMGC Presents PRC 2025! 🌟</h4>
                <p class="center-align animated pulse infinite waves-ripple">
                Join us for an exciting Spelling Competition, Mathematics Competition, and Debate Competition as PMGC marks 12 years of commitment to academic excellence!
                </p>
            </div>
        
            <div class="card-panel blue lighten-4 ">
                <h5 class="red-text text-darken-3">🎯 Why Participate?</h5>
                <ul class="browser-default">
                <li>✅ Showcase your brilliance in Spelling, Mathematics, and Debate</li>
                <li>✅ A total sum of <strong class="bold">#3,000,000</strong> cash prizes to be won plus trophies, medals, placards, and school adverts.</li>
                <li><strong class="bold">
                    Registration closes on 30th of April, 2025
                    <h6 class="red-text">{{ countdown }}</h6>
                </strong></li>
                </ul>
            </div>
        
            <div class="card-panel red lighten-5">
                <h5 class="red-text text-darken-3">📜 Rules & Regulations</h5>
                <p><strong class="bold">Only students aged 6-16 years can participate.</strong></p>
                <p>Categories:</p>
                <ul class="browser-default">
                <li>Primary 3-5</li>
                <li>JSS1-3</li>
                <li>SS1-SS3</li>
                </ul>
                <p>Contest Categories:</p>
                <ul class="browser-default">
                <li>Spelling</li>
                <li>Mathematics</li>
                <li>Debate</li>
                </ul>
                <p class="red-text"><strong class="bold">
                    <i class="icon ion-information-circled"></i>
                    A student cannot participate in more than one category!</strong></p>
            </div>

            <div class="card-panel">
                <h5 class="blue-text text-darken-3">☑️ How to Register</h5>
                <ul class="browser-default">
                <li>Minimum of ten (10) spellers can register for the spelling category.</li>
                <li>Minimum of five (5) students can register for the mathematics category.</li>
                <li>Minimum of five (5) debaters can register for the debate category.</li>
                <li>A school may decide to participate only in one or all of the categories of the competition, such as the Spelling, Debate, or Mathematics categories; this is acceptable according to our policy.</li>
                <li>Registration fee: <strong class="bold">#3200 per participant.</strong></li>
                <li>Upon successful registration, Word lists, Debate topics, and Mathematics syllabus will be sent within 24-72 hours.</li>
                <br/>
                <li>Click <a href="https://forms.gle/2jtnMS4YCpuwTrzV6" target="_blank" class="btn blue darken-3 animated flash infinite">here</a> to proceed to register.</li>
                </ul>
            </div>
        
            <div class="section center-align">
                <p><strong class="bold">📅 Exam Dates Across Geo-Political Zones are attached on the flyer.</strong></p>
                <p>For more details, contact:</p>
                <p>📞 Miss Joy (+2348188020212) | Professor D.O. Makinde (+2348060392066)</p>
                <p>📌 Follow us: @platinummindsglobalcompany on Facebook, IG, LinkedIn, X (Twitter)</p>
            </div>

            <div class="card-action center-align">
                <!-- <a href="https://forms.gle/2jtnMS4YCpuwTrzV6" target="_blank" class="btn red darken-3">Register</a> -->
            </div>

            <br/>

            <div class="card blue lighten-5">
                <div class="card-content">
                    <h4 class="center blue-text">Be our host for Platinum Reasoning Contest 2025 (#PRC2025)...</h4>
                    <p class="flow-text">A call for action to deliver an exceptional educational product to all participants of #PRC2025.</p>
                    <p>Hosting of #PRC2025 in your school is free and comes with lots of benefits:</p>
                    <ul class="collection">
                        <li class="collection-item">✅ Opportunity to register 12 participants at no cost.</li>
                        <li class="collection-item">✅ Your school logo will appear as a backdrop during the ceremony.</li>
                        <li class="collection-item">✅ A certificate of hosting & a medal will be presented to your school.</li>
                    </ul>
                    <p><strong>NOTE:</strong> The 12 students include 4 from each category (Spelling, Mathematics, Debate).</p>
                    
                    <h5 class="blue-text">Requirements:</h5>
                    <ul class="collection">
                        <li class="collection-item">🏫 A hall that can accommodate at least 100-200 students.</li>
                        <li class="collection-item">📍 Your school must be easily accessible and locatable via Google map.</li>
                    </ul>
                    
                    <h5 class="blue-text">To apply:</h5>
                    <p>Kindly send us a message through:</p>
                    <p class="red-text email">platinumminds1@gmail.com</p>
                </div>
            </div>
        </div>
        
        <FooterPage />
        <router-view></router-view>
    </div>
</template>
  
 <script>
import AppNavBar from '@/components/layouts/NavBar.vue'
import FooterPage from '@/components/layouts/footer-page.vue'

  export default {
    name: "PRC2025",
    components: {
        AppNavBar,
        FooterPage
    },
    data () {
        return {
            countdown: '',
            targetDate: new Date('2025-04-30T00:00:00').getTime()
        }
    },
    methods: {
        updateCountdown() {
        const now = new Date().getTime();
        const timeLeft = this.targetDate - now;
                        
            if (timeLeft <= 0) {
            this.countdown = "Time's up!";
            return;
            }
                        
        const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
                        
        this.countdown = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        }
    },
    mounted () {
        this.updateCountdown()
        setInterval(this.updateCountdown, 1000)
    }
  };
  </script>
  
  <style scoped>
  .container {
    margin-top: 20px;
  }
  .card {
    border-radius: 10px;
  }
  .card-panel {
    border-radius: 10px;
    padding: 20px;
  }
  </style>
  